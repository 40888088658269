@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@600;700&family=Inter:wght@300;400;500;600;700&display=swap');

body {
  font-family: "Inter", sans-serif !important;
  margin: 0;
  padding: 0;
  color: white !important;
  overflow-x: hidden;
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-color: #0E0E10 !important;
}

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
p,
a {
  font-family: "Inter", sans-serif !important;
}