#landing {
  width: 85%;
  margin: 60px auto 0 auto;

  .landingContainer {
    display: grid;
    grid-template-columns: 1.1fr .9fr;
    column-gap: 80px;
    row-gap: 30px;
  }

  h1 {
    font-family: "Arimo", sans-serif;
    font-weight: 600;
    font-size: 46px;
    color: white;
  }

  h2 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #B7B7B7;
  }

  h3 {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: white;
    opacity: .7;
  }

  p {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: white;
  }

  .myInfo {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .infoCarousel {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 10px;
    flex-wrap: wrap;

    a {
      text-decoration: none;
    }
  }

  .infoContainer {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;

    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    text-align: center;

    padding: 10px 10px;

    color: rgba(255, 255, 255, 0.7);
  }

  .contactButton {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    transition: 0.2s;
    padding: 10px 30px;
    border-radius: 10px;

    margin-top: 0.5em;
    background-color: white;
    border: 1px solid white;
    color: black;
    text-decoration: none;
    opacity: 1;
  }

  .contactButton:hover {
    background-color: transparent;
    color: white;
  }

  @media (max-width: 992px) {
    width: 90%;

    .landingContainer {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 80px;
    }

    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
}