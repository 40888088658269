#projects {
    width: 85%;
    margin: 100px auto 100px auto;

    h1 {
        font-family: "Arimo", sans-serif;
        font-weight: 600;
        font-size: 42px;
        color: white;
    }

    h2 {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 5px;
    }

    .projectsGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 18px;

        margin-top: 30px;
    }

    .projectContainer {
        position: relative;
        border-radius: 10px;

        .coverImage {
            width: 100%;
            border-radius: 10px;
            // border: 1px solid #313133;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.2s ease;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);

            display: flex;
            justify-content: flex-end;
            flex-direction: column;

            padding: 20px;

            .overlayText {
                position: relative;
                color: white;
                font-size: 18px;
                font-weight: bold;
                text-align: left;
                margin: 0;
            }

            .roleCarousel {
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-top: 10px;

                .roleContainer {
                    background-color: white;
                    color: black;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 10px 15px;
                    border-radius: 10px;
                }
            }
        }
    }

    .projectContainer:hover .overlay {
        opacity: 1;
        cursor: pointer;
    }

    @media (max-width: 1240px) {
        .projectsGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 18px;

            margin-top: 30px;
        }
    }

    @media (max-width: 992px) {
        width: 90%;
        margin: 60px auto 60px auto;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 16px;
            line-height: 24px;
        }
    }

    @media (max-width: 825px) {
        .projectsGrid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 18px;

            margin-top: 30px;
        }
    }
}