#casestudy {
    width: 85%;
    margin: 0 auto;

    h1 {
        font-family: "Arimo", sans-serif;
        font-weight: 600;
        font-size: 36px;
        color: white;
    }

    h2 {
        font-family: "Inter", sans-serif;
        font-weight: 700;
        font-size: 28px;
        color: white;

        margin-top: 30px;
    }

    h3 {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: white;
    }

    p {
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 18px;
        color: white;
    }

    .articleBody {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .articleImage {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .articleGrid1 {
        display: grid;
        grid-template-columns: 1.2fr .8fr;
        gap: 30px;
        // margin-top: 30px;
    }

    .ventureHeader {
        margin-bottom: 20px;
    }

    .linkGroup {
        display: flex;
        flex-direction: row;
        gap: 9px;
        margin-bottom: 14px;
        align-items: center;
        text-decoration: none;

        opacity: .7;
        transition: .2s;

        width: fit-content;

        .linkIcon {
            width: 19px;
            height: 19px;
        }

        p {
            margin: 0;
            font-weight: 400;
            font-size: 16px;
        }
    }

    .linkGroup:hover {
        cursor: pointer;
        opacity: 1;
    }

    .ventureDescription {
        font-size: 16px;
        margin-top: 20px;
    }

    @media (max-width: 992px) {
        width: 90%;

        h1 {
            font-size: 28px;
        }

        h2 {
            font-size: 22px;
        }

        p {
            font-size: 16px;
        }

        .articleGrid1 {
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;
        }
    }
}