#navbar {
  position: relative;
  z-index: 999;
  padding: 30px 0;

  .navbarWidth {
    width: 85%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;
  }

  .navlogo {
    width: 45px;
    margin-right: 69.23px;
  }

  .navLinks {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }

  a {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;

    color: white;
    opacity: 0.7;
    text-decoration: none;
    transition: .2S;
  }

  a:hover {
    opacity: 1;
    cursor: pointer;
  }

  .contactButton {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    transition: 0.2s;
    padding: 10px 30px;
    border-radius: 10px;

    margin-top: 0.5em;
    background-color: white;
    border: 1px solid white;
    color: black;
    text-decoration: none;
    opacity: 1;
  }

  .contactButton:hover {
    background-color: transparent;
    color: white;
  }

  @media (max-width: 992px) {
    .navbarWidth {
      width: 90%;
    }
  }

  @media (max-width: 768px) {
    .navLinks {
      display: none;
    }

    .navlogo {
      width: 40px;
      margin-right: 69.23px;
    }
  }
}