#ventures {
    width: 85%;
    margin: 150px auto 0 auto;

    h1 {
        font-family: "Arimo", sans-serif;
        font-weight: 600;
        font-size: 42px;
        color: white;
    }

    h2 {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 5px;
    }

    .ventureGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        margin-top: 30px;
    }

    .ventureCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 25px;
        gap: 20px;
        transition: .2s;

        background: #1A1A1C;
        border: 1px solid #313133;
        border-radius: 10px;

        .ventureHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .logo {
                width: 65px;
            }

            h3 {
                font-family: "Inter", sans-serif;
                font-weight: 600;
                font-size: 20px;
                color: white;
                margin: 0;
            }
        }

        p {
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .ventureCard:hover {
        background-color: #2E2E32;
        border: white 1px solid;
    }

    @media (max-width: 992px) {
        width: 90%;
        margin: 60px auto 0 auto;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 16px;
            line-height: 24px;
        }

        .ventureCard {
            padding: 20px;

            .ventureHeader {
                h3 {
                    font-family: "Inter", sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    color: white;
                    margin: 0;
                }
            }

            p {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 768px) {
        .ventureGrid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;

            margin-top: 30px;
        }
    }
}